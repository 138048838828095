import classnames from "classnames";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import Item from "./Item";
import useFeaturedProjects from "./useFeaturedProjects";
import "./style.css";

const ProjectsHero = ({
  className,
  loading: isLoading,
  onLoaded: handleIsLoaded,
  ...props
}) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const featuredProjects = useFeaturedProjects();

  useEffect(() => {
    if (featuredProjects.length > 0) {
      setCurrentIndex(0);
    }
  }, [featuredProjects.length, setCurrentIndex]);

  // TEMPORARY
  useEffect(() => {
    setTimeout(() => {
      handleIsLoaded();
    }, 1000 + Math.random() * 1000);
  }, [handleIsLoaded]);

  const { currentProject, nextIndex, nextProject } = useMemo(() => {
    if (currentIndex === -1 || currentIndex >= featuredProjects.length) {
      return {
        currentProject: null,
        nextIndex: -1,
        nextProject: null,
      };
    }
    const currentProject = featuredProjects[currentIndex];
    const nextIndex = (currentIndex + 1) % featuredProjects.length;
    const nextProject = featuredProjects[nextIndex];
    return { currentProject, nextIndex, nextProject };
  }, [currentIndex, featuredProjects]);

  const handleNext = useCallback(() => {
    setCurrentIndex(nextIndex);
  }, [setCurrentIndex, nextIndex]);

  return (
    <section
      className={classnames(
        "projects-hero",
        {
          "projects-hero--loading": isLoading,
        },
        className
      )}
      {...props}
    >
      <div className="projects-hero__inner">
        {!isLoading && (
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={currentIndex}
              appear
              mountOnEnter
              unmountOnExit
              classNames={"projects-hero-item-"}
              timeout={{
                appear: 2000,
                enter: 2000,
                exit: 3000,
              }}
              {...props}
            >
              {state => (
                <Item
                  index={currentIndex}
                  projectsCount={featuredProjects.length}
                  currentProject={currentProject}
                  nextProject={nextProject}
                  transitionState={state}
                  onNext={handleNext}
                />
              )}
            </CSSTransition>
          </SwitchTransition>
        )}
      </div>
    </section>
  );
};

export default ProjectsHero;
