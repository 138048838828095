import React from "react";
import Grid from "@components/Grid";
import MorphLink from "@components/MorphLink";
import "./style.css";

const Project = ({ image, title, tagline, uri }) => {
  return (
    <article
      className="projects-hero-project"
      style={{
        backgroundImage: image ? `url(${image})` : null,
      }}
    >
      <Grid.Container fluid narrowMargins>
        <Grid.Row>
          <Grid.Cell>
            <div className="projects-hero-project__content">
              <div className="projects-hero-project__title-wrapper">
                <h1 className="projects-hero-project__title">{title}</h1>
                <div className="projects-hero-project__tagline">{tagline}</div>
              </div>

              <div className="projects-hero-project__cta-wrapper">
                <MorphLink
                  to={`/${uri}`}
                  className="projects-hero-project__cta"
                  duration={2}
                  delay={0.4}
                  morph={{
                    from: `.projects-hero-project`,
                    to: ".project-cover .container",
                  }}
                >
                  Discover this project
                </MorphLink>
              </div>
            </div>
          </Grid.Cell>
        </Grid.Row>
      </Grid.Container>
    </article>
  );
};
export default Project;
