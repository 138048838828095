import { graphql, useStaticQuery } from "gatsby";

const useFeaturedProjects = () => {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        projects(where: { orderby: { field: MENU_ORDER, order: ASC } }) {
          nodes {
            uri
            acf {
              featured
              titleColourImageOverlap
            }
            blocks {
              ... on WPGraphQL_AcfProjectCoverBlock {
                acf {
                  image {
                    sourceUrl(size: _1536X1536)
                  }
                  projectName
                  tagline
                }
              }
            }
          }
        }
      }
    }
  `);

  return data.wpgraphql.projects.nodes
    .filter(p => p.acf.featured)
    .map(p => {
      const coverBlock = p.blocks.find(
        b => b.__typename === "WPGraphQL_AcfProjectCoverBlock"
      );

      return {
        uri: p.uri,
        image: coverBlock?.acf?.image?.sourceUrl ?? null,
        title: coverBlock?.acf?.projectName ?? null,
        tagline: coverBlock?.acf?.tagline ?? null,
        textColor: p.acf.titleColourImageOverlap,
      };
    });
};

export default useFeaturedProjects;
