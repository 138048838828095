import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
} from "react";
import classnames from "classnames";

import "./style.css";

const DELAY = 2000;
const DURATION = 9000;

const Countdown = forwardRef(({ image, onNext, ...props }, ref) => {
  const progressCursor = useRef(null);
  const RAF = useRef(null);
  const initTime = useRef(-1);
  const delay = useRef(0);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const isOnTop = useRef(true);

  const handleNext = useCallback(() => {
    setIsLoadingNext(true);
    onNext();
  }, [onNext]);

  const handleAnimationFrame = useCallback(
    t => {
      if (initTime.current === -1) {
        initTime.current = t - delay.current;
        delay.current = 0;
      }
      const progress = Math.min(
        1,
        Math.max((t - initTime.current - DELAY) / DURATION, 0)
      );

      if (progressCursor.current) {
        const strokeDashoffset = Math.PI * 2 * 57 * (1 - progress);
        progressCursor.current.style.strokeDashoffset = strokeDashoffset;
      }

      if (isOnTop.current) {
        if (progress < 1) {
          RAF.current = requestAnimationFrame(handleAnimationFrame);
        } else {
          handleNext();
          cancelAnimationFrame(RAF.current);
          RAF.current = null;
        }
      } else {
        delay.current = t - initTime.current;
        initTime.current = -1;
        cancelAnimationFrame(RAF.current);
        RAF.current = null;
      }
    },
    [handleNext, isOnTop.current]
  );

  useEffect(() => {
    if (!RAF.current) {
      RAF.current = requestAnimationFrame(handleAnimationFrame);
    }

    return () => {
      if (RAF.current) {
        cancelAnimationFrame(RAF.current);
        RAF.current = null;
      }
    };
  }, [handleAnimationFrame]);

  const handleWindowScroll = useCallback(() => {
    if (isOnTop.current && window.scrollY > 0) {
      isOnTop.current = false;
    } else if (!isOnTop.current && window.scrollY === 0) {
      isOnTop.current = true;

      if (!RAF.current) {
        RAF.current = requestAnimationFrame(handleAnimationFrame);
      }
    }
  }, []);

  useEffect(() => {
    isOnTop.current = window.scrollY === 0;

    window.addEventListener("scroll", handleWindowScroll);

    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, [handleWindowScroll]);

  return (
    <button
      ref={ref}
      className={classnames("projects-hero-countdown", {
        "projects-hero-countdown--loading": isLoadingNext,
      })}
      style={{ backgroundImage: `url(${image})` }}
      onClick={handleNext}
    >
      <div className="projects-hero-countdown__inner">Next</div>

      <svg viewBox="0 0 120 120" className="projects-hero-countdown__progress">
        <circle
          ref={progressCursor}
          className="projects-hero-countdown__progress-cursor"
          cx="60"
          cy="60"
          r="57"
          fill="transparent"
          strokeDasharray={`${Math.PI * 2 * 57} ${Math.PI * 2 * 57}`}
          style={{ strokeDashoffset: Math.PI * 2 * 57 * 1 }}
        />
        <circle
          className="projects-hero-countdown__progress-track"
          cx="60"
          cy="60"
          r="57"
          fill="transparent"
        />
      </svg>
    </button>
  );
});

export default Countdown;
