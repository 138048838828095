import { quadOut, quintInOut } from "eases";
import React, { useRef, useCallback, useEffect } from "react";

import "./style.css";

const OPACITY_DURATION = 400;
const ZOOM_IN_DURATION = 1800;
const ZOOM_OUT_DURATION = 800;

const Curtain = ({ transitionState, countdownRef, image: imageSrc, index }) => {
  const curtain = useRef(null);
  const image = useRef(null);

  const RAF = useRef(null);
  const RAFState = useRef({
    startTime: -1,
    countdown: {
      x: -1,
      y: -1,
      width: -1,
      height: -1,
    },
    window: {
      width: -1,
      height: -1,
      maxSize: -1,
    },
  });

  const handleResize = useCallback(() => {
    RAFState.current.countdown = countdownRef.current.getBoundingClientRect();
    RAFState.current.curtain = {
      width: curtain.current.offsetWidth,
      height: curtain.current.offsetHeight,
      maxSize: Math.max(
        curtain.current.offsetWidth,
        curtain.current.offsetHeight
      ),
    };
    RAFState.current.outerRadius =
      0.5 *
      Math.sqrt(
        RAFState.current.curtain.width * RAFState.current.curtain.width +
          RAFState.current.curtain.height * RAFState.current.curtain.height
      );

    RAFState.current.outerWidth =
      RAFState.current.curtain.width > RAFState.current.curtain.height
        ? RAFState.current.outerRadius * 2
        : (RAFState.current.outerRadius * 2 * RAFState.current.curtain.width) /
          RAFState.current.curtain.height;
    RAFState.current.outerHeight =
      RAFState.current.curtain.width > RAFState.current.curtain.height
        ? (RAFState.current.outerRadius * 2 * RAFState.current.curtain.height) /
          RAFState.current.curtain.width
        : RAFState.current.outerRadius * 2;

    RAFState.current.initialScale =
      RAFState.current.countdown.width / 2 / RAFState.current.outerRadius;
    RAFState.current.targetScale =
      RAFState.current.curtain.maxSize / 2 / RAFState.current.outerRadius;

    RAFState.current.initialX =
      -image.current.offsetLeft +
      RAFState.current.countdown.x +
      RAFState.current.countdown.width / 2;
    RAFState.current.targetX = 0;
    RAFState.current.initialY =
      -image.current.offsetTop +
      RAFState.current.countdown.y +
      RAFState.current.countdown.height / 2;
    RAFState.current.targetY = 0;
  }, [countdownRef]);

  const handleAnimationFrame = useCallback(
    t => {
      if (RAFState.current.startTime === -1) {
        RAFState.current.startTime = t;

        handleResize();

        image.current.style.width = `${RAFState.current.outerRadius * 2}px`;
        image.current.style.height = `${RAFState.current.outerRadius * 2}px`;
        image.current.style.clipPath = `circle(${RAFState.current.outerRadius}px at 50% 50%)`;
        image.current.style.transform = `translate(-50%, -50%) translate(${RAFState.current.initialX}px, ${RAFState.current.initialY}px) scale(${RAFState.current.initialScale})`;
      }

      const deltaTime = t - RAFState.current.startTime;
      const opacityProgress = quadOut(
        Math.min(1, Math.max(deltaTime / OPACITY_DURATION, 0))
      );
      const zoomInProgress = quintInOut(
        Math.min(
          1,
          Math.max((deltaTime - OPACITY_DURATION) / ZOOM_IN_DURATION, 0)
        )
      );
      const zoomOutProgress = quintInOut(
        Math.min(
          1,
          Math.max(
            (deltaTime - OPACITY_DURATION - ZOOM_IN_DURATION) /
              ZOOM_OUT_DURATION,
            0
          )
        )
      );

      // console.log(opacityProgress, zoomInProgress, zoomOutProgress);

      // calculate animation state
      const currentScale =
        zoomInProgress < 1
          ? RAFState.current.initialScale +
            (1 - RAFState.current.initialScale) * zoomInProgress
          : 1 + (RAFState.current.targetScale - 1) * zoomOutProgress;
      const currentX =
        RAFState.current.initialX +
        (RAFState.current.targetX - RAFState.current.initialX) * zoomInProgress;
      const currentY =
        RAFState.current.initialY +
        (RAFState.current.targetY - RAFState.current.initialY) * zoomInProgress;
      // const currentScale = RAFState.current.initialScale;
      // const currentX = RAFState.current.initialX;
      // const currentY = RAFState.current.initialY;

      const currentWidth =
        zoomInProgress < 1
          ? RAFState.current.outerRadius * 2
          : RAFState.current.outerRadius * 2 +
            (RAFState.current.outerWidth - RAFState.current.outerRadius * 2) *
              zoomOutProgress;
      const currentHeight =
        zoomInProgress < 1
          ? RAFState.current.outerRadius * 2
          : RAFState.current.outerRadius * 2 +
            (RAFState.current.outerHeight - RAFState.current.outerRadius * 2) *
              zoomOutProgress;

      // apply animation state to curtain
      curtain.current.style.opacity = opacityProgress;

      if (zoomInProgress >= 1) {
        image.current.style.width = `${currentWidth}px`;
        image.current.style.height = `${currentHeight}px`;
      }

      image.current.style.transform = `translate(-50%, -50%) translate(${currentX}px, ${currentY}px) scale(${currentScale})`;
      image.current.style.clipPath =
        zoomInProgress < 1
          ? `circle(${RAFState.current.outerRadius}px at 50% 50%)`
          : `circle(100%)`;

      if (zoomOutProgress < 1) {
        RAF.current =
          RAF.current && requestAnimationFrame(handleAnimationFrame);
      } else {
        cancelAnimationFrame(RAF.current);
        RAF.current = null;
      }
    },
    [handleResize]
  );

  useEffect(() => {
    console.log("mounted handle resize", index);
    window.addEventListener("resize", handleResize);

    return () => {
      console.log("unmounted handle resize", index);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (transitionState === "exiting" && RAF.current === null) {
      RAF.current = requestAnimationFrame(handleAnimationFrame);
    }
  }, [transitionState, handleAnimationFrame]);

  useEffect(() => {
    console.log("mounted Curtain", index);
    return () => {
      console.log("unmounted Curtain", index);
      if (RAF.current) {
        cancelAnimationFrame(RAF.current);
        RAF.current = null;
      }
    };
  }, []);

  return (
    <div ref={curtain} className="projects-hero-curtain">
      <div
        ref={image}
        className="projects-hero-curtain__image"
        style={{ backgroundImage: `url(${imageSrc})` }}
      ></div>
    </div>
  );
};

export default Curtain;
