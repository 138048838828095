import React, { useRef, useEffect } from "react";
import Grid from "@components/Grid";
import Project from "../Project";
import Curtain from "../Curtain";
import Countdown from "../Countdown";

const Item = ({
  index,
  projectsCount,
  currentProject,
  nextProject,
  transitionState,
  onNext: handleNext,
  ...props
}) => {
  const countdown = useRef(null);

  useEffect(() => {
    console.log("item mounted", index);

    return () => {
      console.log("item unmounted", index);
    };
  }, []);

  return (
    <div className="projects-hero-item" {...props}>
      <Project {...currentProject} />

      <Curtain
        index={index}
        countdownRef={countdown}
        transitionState={transitionState}
        {...nextProject}
      />

      <Grid.Container
        fluid
        className="projects-hero__status-line"
        narrowMargins
      >
        <Grid.Row>
          <Grid.Cell>
            <div className="projects-hero__index">
              {index + 1}/{projectsCount}
            </div>

            <Countdown ref={countdown} {...nextProject} onNext={handleNext} />
          </Grid.Cell>
        </Grid.Row>
      </Grid.Container>
    </div>
  );
};

export default Item;
